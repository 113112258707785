/**
 * 登录须知model
 */
import {getLoginConfig, saveLoginConfig} from "@/api/exp/LoginConfigApi";
import {CommonModel} from "@/model/CommonModel";

class LoginConfigModel {
    // 获取配置列表
    static async getList(page, size, query) {
        let params = {
            page: page,
            size: size,
        }
        params = Object.assign(params, query)
        let [data] = await getLoginConfig(params)
        let list = CommonModel.generateListMybatisPlus(data.data)
        // 遍历config转换为对象
        let listData = list[0]
        for (let i = 0; i < listData.length; i++) {
            if (listData[i].configs) {
                listData[i].configs = JSON.parse(listData[i].configs)
            } else {
            }
        }
        return [listData, list[1]];
    }

    // 获取公共配置
    static async getCommonConfig() {
        let [data] = await getLoginConfig(null)
        if (data.code === 20000) {
            let config = JSON.parse(data.data)
            config.schoolid = null
            return ({
                id: null,
                configs: config,
                name: "公共配置"
            })
        } else {
            return {
                id: null,
                configs: {
                    schoolid: null
                },
                name: "公共配置"
            }
        }
    }

    static async getLoginConfig(schoolId) {
        async function getOnlineConfig() {
            let loginPageConfig = {}
            // 获取登录页公共配置
            let commonConfig = await LoginConfigModel.getCommonConfig()
            loginPageConfig = commonConfig.configs
            // 判断有无学校参数
            if (schoolId) { // 学校直接登录模式
                let schoolConfig = (await LoginConfigModel.getList(1, 1, {
                    schoolid: schoolId
                }))[0][0]
                if (schoolConfig) {// 如果有配置
                    if (schoolConfig.configs !== null) {
                        if (schoolConfig.configs.title && schoolConfig.configs.baseImg) {
                            loginPageConfig = schoolConfig.configs
                        }
                    }
                } else {// 如果学校id输入错误，跳转回登录页
                    window.location.href = "/login"
                }
            }
            // 判断是否是学校登录
            if (schoolId) {
                loginPageConfig.asSchoolLogin = true
            }
            sessionStorage.setItem("exp_schoolLoginPageConfig", JSON.stringify(loginPageConfig))
            return loginPageConfig
        }

        return getOnlineConfig()
    }
}

export {LoginConfigModel}
