<template>
  <div>
    <div class="login-container">
      <!--背景图-->
      <ul class="cb-slideshow">
        <li :style="`background-image:url(${loginPageConfig.baseImg})`"></li>
      </ul>
      <div class="title-container" :style="isPhone?'padding-top:50px':''">
        <h3 class="title-img">
          <img :src="loginPageConfig.icon" alt="" v-if="loginPageConfig.icon" @click="clickLoginTitleImg()">
        </h3>
        <h3 class="title" :style="isPhone?'font-size:25px':''">
          {{ loginPageConfig.title }}
        </h3>
        <h4 class="sub-title">

        </h4>
      </div>
      <!--输入框-->
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" autocomplete="off"
               label-position="left">


        <el-form-item prop="schoolName" v-if="!asSchool">
        <span class="svg-container">
          <i class="el-icon-school"></i>
        </span>
          <el-input
              :style="isPhone?'width:80%':''"
              ref="schoolname"
              v-model="loginForm.schoolName"
              name="schoolName"
              placeholder="请输入学校名称"
              onKeypress="javascript:if(event.keyCode == 32 || event.keyCode == 43)event.returnValue = false;"
              type="text"
              tabindex="1"
              @change="onSchoolNameChange"
              autocomplete="off"
          />
        </el-form-item>

        <el-form-item prop="account">
        <span class="svg-container">
          <i class="el-icon-user"></i>
        </span>
          <el-input
              :style="isPhone?'width:80%':''"
              ref="account"
              onKeypress="javascript:if(event.keyCode == 32 || event.keyCode == 43)event.returnValue = false;"
              v-model="loginForm.account"
              name="account"
              placeholder="请输入用户名"
              type="text"
              tabindex="2"
              @change="onLoginAccountChange"
              autocomplete="off"
          />
        </el-form-item>

        <!--选择教师-->
        <el-form-item prop="teacherid" v-if="loginForm.asStudentLogin">
                <span class="svg-container">
                   <i class="el-icon-s-custom"></i>
                </span>
          <el-select
              :style="isPhone?'width:80%':''"
              v-model="loginForm.teacherid"
              tabindex="3"
              autocomplete="off"
              style="width:93%;outline: none"
              placeholder="请选择教师"
          >
            <el-option v-for="item in loginForm.teacherList" :label="item.label" :value="item.value"
                       :key="item.value"></el-option>
          </el-select>
          <span class="teacher-tip">授课教师</span>
        </el-form-item>

        <el-tooltip v-model="capsTooltip" content="大写键已打开" placement="right" manual>
          <el-form-item prop="password">
          <span class="svg-container">
            <i class="el-icon-lock"></i>
          </span>
            <el-input
                :style="isPhone?'width:80%':''"
                onKeypress="javascript:if(event.keyCode == 32 || event.keyCode == 43)event.returnValue = false;"
                :key="passwordType"
                ref="password"
                v-model="loginForm.password"
                :type="passwordType"
                placeholder="请输入密码"
                name="password"
                tabindex="4"
                autocomplete="off"
                @keyup.native="checkCapslock"
                @blur="capsTooltip = false"
                @keyup.enter.native="handleLogin"
            />
            <span class="show-pwd" @click="showPwd">
              <i class="el-icon-view"></i>
            </span>
          </el-form-item>
        </el-tooltip>


        <div class="btn-box">
          <el-button round :loading="loginLoading" type="primary"
                     style="display:block;width:35%;margin:0 auto;margin-bottom:30px;font-size: 17px"
                     @click.native.prevent="handleLogin">
            登 录
          </el-button>
          <el-button type="text" class="btn-forget" @click="clickForgetPasswordBtn">忘记密码？</el-button>
        </div>
        <div style="text-align: center;margin-top: -10px" v-if="isDefaultSchool">
          <el-button @click="clickMoreBtn" type="primary" round>更多教学资源</el-button>
        </div>
        <div style="text-align: center;margin-top: -10px" v-if="hasWeb">
          <el-button @click="clickWebBtn" type="primary" round v-if="!getQuery('returnUrl')">进入官网</el-button>
        </div>

        <div class="system-tips" v-if="isPhone">
          <div class="mobile-bottom-tips flex flex-dr flex-center"
               style="margin-top: 20px;text-align: center">
            <div>技术支持：</div>
            <div>成都智云鸿道信息技术有限公司</div>
            <div v-if="isDefaultSchool">
              <div>联系电话：</div>
              <div>13550013785 028-61551840</div>
            </div>
            <div style="cursor: pointer" @click="window.open('https://beian.miit.gov.cn/')">蜀ICP备19034784号-1</div>
            <div>推荐使用Chrome谷歌、Firefox火狐、360极速浏览器、Edge等浏览器访问本系统</div>
          </div>
        </div>
      </el-form>
      <!--底部信息-->
      <div class="bottomTips" v-if="!isPhone">
        <div>技术支持：成都智云鸿道信息技术有限公司 <span v-if="isDefaultSchool">联系电话：13550013785 028-61551840</span></div>
        <div style="cursor: pointer" @click="window.open('https://beian.miit.gov.cn/')">蜀ICP备19034784号-1</div>
        <div>推荐使用Chrome谷歌、Firefox火狐、360极速浏览器、Edge等浏览器访问本系统</div>
      </div>
    </div>
    <!--弹出框-找回密码-->
    <el-dialog
        title="重置密码"
        :visible.sync="findPasswordShow"
        width="400px"
        :close-on-click-modal="false"
        center
        v-el-drag-dialog>
      <div class="dialog-container">
        <el-form ref="resetForm" :model="resetForm" :rules="resetRules">
          <el-form-item prop="account">
            <el-input placeholder="请输入用户名" v-model="resetForm.account" @change="onResetAccountChange"></el-input>
          </el-form-item>
          <!--选择教师-->
          <el-form-item prop="teacherid" v-if="resetForm.asStudentLogin">
            <el-select
                v-model="resetForm.teacherid"
                autocomplete="off"
                style="width:100%;outline: none"
                placeholder="请选择教师"
            >
              <el-option v-for="item in resetForm.teacherList" :label="item.label" :value="item.value"
                         :key="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="email">
            <el-input placeholder="请输入绑定的邮箱" v-model="resetForm.email"
                      @keyup.enter.native="clickResetPasswordBtn"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="primary" @click="clickResetPasswordBtn">重置密码</el-button>
      </div>
    </el-dialog>
    <!--弹出框-完善信息-->
    <el-dialog
        title="首次登录，请完善信息"
        :visible.sync="addInfoShow"
        width="400px"
        center
        :close-on-click-modal="false"
        v-el-drag-dialog>
      <div class="dialog-container">
        <el-form ref="addInfoForm" :model="addInfoForm" :rules="addInfoRules">
          <el-form-item prop="password1">
            <el-input placeholder="请输入6-12位的新密码" v-model="addInfoForm.password1"
                      onKeypress="javascript:if(event.keyCode == 32 || event.keyCode == 43)event.returnValue = false;"
                      type="password"></el-input>
          </el-form-item>
          <el-form-item prop="password2">
            <el-input placeholder="请再次输入新密码" v-model="addInfoForm.password2" type="password"
                      onKeypress="javascript:if(event.keyCode == 32 || event.keyCode == 43)event.returnValue = false;"></el-input>
          </el-form-item>
          <el-form-item prop="email">
            <el-input placeholder="请输入邮箱地址，便于找回密码" v-model="addInfoForm.email"
                      @keyup.enter.native="clickAddInfoBtn"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="primary" @click="clickAddInfoBtn">确 认</el-button>
      </div>
    </el-dialog>
    <!--弹出框-超级用户-->
    <el-dialog
        class="super-user"
        :visible.sync="superUserShow"
        title=" "
        width="400px"
        center
        :close-on-click-modal="false"
        v-el-drag-dialog>
      <div class="dialog-container">
        <el-form ref="superUserForm" :model="superUserForm" :rules="superUserRules">
          <el-form-item style="visibility: hidden;margin-top: -40px;margin-left: -100px;width: 1px;">
            <el-input placeholder="隐藏"></el-input>
          </el-form-item>
          <el-form-item prop="account">
            <el-input placeholder="请输入教师用户名" v-model="superUserForm.account" tabindex="0"
                      @keyup.enter.native="clickSuperAdminLoginBtn"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="primary" @click="clickSuperAdminLoginBtn">登录</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>/**
 * 登录页配置逻辑
 * 参考：https://lanhuapp.com/web/#/item/project/product?pid=3e934892-21db-4359-be91-4bee652e03cc&image_id=f3db4870-daa4-4b11-acf9-bd9ee3ba77b0&versionId=72e42cbe-1d16-4a41-97f5-dfcf218c56b6&docId=f3db4870-daa4-4b11-acf9-bd9ee3ba77b0&docType=axure&pageId=c68dc49e46ed4b17bb842139a2fab719&parentId=49f25785-0362-4362-93b0-e5750be746d9
 * 如果有schoolId的query参数，就获取这个学院的配置
 * 如果没有就显示公共配置，增加选择学校
 */
import {LoginConfigModel} from "@/model/exp/LoginConfigModel";
import {SchoolModel} from "@/model/exp/SchoolModel";
import {CommonModel} from "@/model/CommonModel";
import elDragDialog from "@/directive/el-drag-dialog";
import {getQuery, isMobile, regCheck, shareStart, validateMaxLength} from "@/utils/common";
import {LoginModel} from "@/model/exp/LoginModel";
import {RouterModel} from "@/model/RouterModel";
import {msg_err, msg_success} from "@/utils/ele_component";
import {OFFICIAL_H5_WEB_URL, OFFICIAL_WEB_URL} from "@/model/ConfigModel";
import {EnumsModel} from "@/model/EnumsModel";

// 默认测试学校id
let defaultSchoolId = "49533847984934912"

export default {
  name: 'Login',
  components: {},
  directives: {
    elDragDialog
  },
  data() {
    // 用户名输入检测
    const validateAccount = (rule, value, callback, maxLength, propName) => {
      if (!(value === 0 || value)) {
        callback(new Error('请输入' + propName))
        return
      }
      if (value.length > maxLength) {
        callback(new Error('最多输入' + maxLength + '个字，当前已输入' + value.length + "个字"))
      }
      // 特殊字符检测
      let regEn = /[`!#$%^&*()_+<>?:"{},.\/;'[\]]/im,
          regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;
      if (regEn.test(value) || regCn.test(value)) {
        callback(new Error('仅支持数字、字母、部分符号'));
      }
      callback()
    }

    // 邮箱输入检测
    const validateEmail = (rule, value, callback) => {
      if (!regCheck("email", value)) {
        callback(new Error("邮箱格式错误"))
      }
      callback()
    }
    // 密码输入检测
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6 || value.length > 15) {
        callback(new Error("请输入6-15位密码"))
      }
      callback()
    }
    // 密码输入检测
    const validatePassword2 = (rule, value, callback) => {
      if (value.length < 6 || value.length > 15) {
        callback(new Error("请再次输入6-15位密码"))
      }
      if (this.addInfoForm.password1) {
        if (this.addInfoForm.password1 !== value) {
          callback(new Error("两次密码输入不一致"))
        }
      }
      callback()
    }
    return {
      getQuery:getQuery,
      window: window,
      // 教师列表-需要选择教师时
      loginForm: {
        account: '',
        password: '',
        schoolId: '',
        schoolName: '',
        asStudentLogin: false,// 是否是学生登录或者用户名错误
        teacherid: null,// 一个学校存在多个相同学生时，学生主动选择教师
        teacherList: [],
      },
      loginRules: {
        schoolName: [{required: true, trigger: 'blur', validator: (r, v, c) => validateAccount(r, v, c, 30, '学校名称')}],
        account: [{required: true, trigger: 'blur', validator: (r, v, c) => validateAccount(r, v, c, 30, '用户名')}],
        password: [{required: true, trigger: 'blur', validator: validatePassword}],
        teacherid: [{required: true, trigger: 'blur', message: '请选择教师'}]
      },
      passwordType: 'password',
      capsTooltip: false,
      loginLoading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      // 登录页配置
      asSchool: false,// 是否是学校之间登录模式
      loginPageConfig: {},
      // 登录页公共配置
      loginPageCommonConfig: {},
      schoolId: this.$route.query['schoolId'],
      // 找回密码、重置密码
      resetForm: {
        account: "",
        email: "",
        asStudentLogin: false,// 是否是学生登录或者用户名错误
        teacherid: null,// 一个学校存在多个相同学生时，学生主动选择教师
        teacherList: [],
      },
      resetRules: {
        account: [{required: true, trigger: 'blur', message: '请输入用户名'}],
        email: [{required: true, trigger: 'blur', validator: validateEmail}],
        teacherid: [{required: true, trigger: 'blur', message: '请选择教师'}]
      },
      findPasswordShow: false,
      // 完善信息
      addInfoForm: {
        password1: "",
        password2: "",
        email: ""
      },
      addInfoRules: {
        password1: [{required: true, trigger: 'blur', validator: validatePassword}],
        password2: [{required: true, trigger: 'blur', validator: validatePassword2}],
        email: [{required: true, trigger: 'blur', validator: validateEmail}]
      },
      addInfoShow: false,
      superUserShow: false,
      superUserForm: {
        account: ""
      },
      superUserRules: {
        account: [{required: true, trigger: 'blur', message: "请输入教师用户名"}]
      },
      userInfo: {},// 登录成功后用户信息
      isDefaultSchool: false,// 标记是否是默认学校或未输入学校
      isPhone: isMobile(),
      hasWeb: false,// 是否有官网
    }
  },
  async mounted() {
    if (!getQuery("schoolId") || getQuery("schoolId") === defaultSchoolId) { // 是否是默认学校
      this.isDefaultSchool = true
    }
    if (EnumsModel.webSchool.idList.indexOf(this.schoolId) !== -1) {// 判断是否拥有官网
      this.hasWeb = true
    }
    let clientHeight = document.documentElement.clientHeight
    this.getLoginConfig()
    // 输入框自动获取焦点
    if (!this.schoolId) {
      this.$refs.schoolname.focus()
    } else {
      if (this.loginForm.account === '') {
        this.$refs.account.focus()
      } else if (this.loginForm.password === '') {
        this.$refs.password.focus()
      }
    }

    // 微信手机分享
    let script3 = document.createElement('script');
    script3.id = 'jweixin';
    script3.src = 'http://res.wx.qq.com/open/js/jweixin-1.6.0.js';
    document.body.append(script3);
    script3.addEventListener('load', e => {
      // 配置微信分享参数
      setTimeout(() => {
        // 判断是否在微信内
        if (navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1) {
          shareStart(window.location.href, "思政课虚拟仿真体验教学中心", "思政树立理想 科技点亮初心", "https://exp.cdzyhd.com/images/share_logo.jpg")
        }
      }, 500)
    });
  },
  methods: {
    // 点击进入官网按钮
    clickWebBtn() {
      let webUrl = EnumsModel.webSchool.idToRoute[this.schoolId]
      window.location.href = webUrl
    },
    // 点击更多教学资源按钮
    clickMoreBtn() {
      if (isMobile()) {
        window.location.href = OFFICIAL_H5_WEB_URL + "/product"
      } else {
        window.location.href = OFFICIAL_WEB_URL + "/product"
      }
    },
    // 点击登录头像框
    clickLoginTitleImg() {
      // 如果没有学校id，是默认登录页，点击就跳转到产品中心
      if (this.isDefaultSchool) {
        window.location.href = OFFICIAL_WEB_URL + "/product"
      }
    },
    // 登录框-学校名称改变
    async onSchoolNameChange(v) {
    },
    // 登录框-账户输入改变
    async onLoginAccountChange(account) {

    },
    // 获取登录配置
    async getLoginConfig() {
      this.loginPageConfig = await LoginConfigModel.getLoginConfig(this.schoolId)
      // 判断有无学校参数
      if (this.schoolId) { // 学校直接登录模式
        this.asSchool = true
        // 设置登录学校参数
        this.loginForm.schoolId = this.schoolId
      } else {// 公共登录模式

      }
    },
    // 检测大小写
    checkCapslock(e) {
      const {key} = e
      this.capsTooltip = key && key.length === 1 && (key >= 'A' && key <= 'Z')
    },
    // 显示密码
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    // 处理登录
    handleLogin() {
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          this.loginLoading=true
          // 判断通过学校名称还是id登录
          let resultData = ""
          if (this.schoolId) {// 通过学校id登录
            resultData = await LoginModel.expLogin(this.loginForm.account, this.loginForm.password, "schoolId", this.schoolId, null, this.loginForm.teacherid).catch(err=>{this.loginLoading=false})
          } else {// 通过学校名称登录
            resultData = await LoginModel.expLogin(this.loginForm.account, this.loginForm.password, "schoolName", this.loginForm.schoolName, null, this.loginForm.teacherid).catch(err=>{this.loginLoading=false})
          }
          this.userInfo = resultData
          if (resultData.hasOwnProperty("asSuperAdminLogin")) {// 是否是超级管理员登录
            this.superUserShow = true
            return true
          }
          // 正常登录
          if (resultData.loginsta === 0) {// 从未登录
            this.openAddInfoDialog()
          } else {// 跳转到对应角色页面
            this.jumpToIndex()
          }
        } else {
          return false
        }
        this.loginLoading=false
      })
    },
    // 打开完善信息框
    openAddInfoDialog() {
      this.addInfoForm = {
        password1: "",
        password2: "",
        email: ""
      }
      this.addInfoShow = true
      setTimeout(() => {
        this.$refs.addInfoForm.clearValidate()
      }, 300)
    },
    // 点击超级用户登录按钮
    async clickSuperAdminLoginBtn() {
      this.$refs.superUserForm.validate(async valid => {
        if (valid) {
          let resultData = ""
          if (this.schoolId) {// 通过学校id登录
            resultData = await LoginModel.expLogin(this.loginForm.account, this.loginForm.password, "schoolId", this.schoolId, this.superUserForm.account)
          } else {// 通过学校名称登录
            resultData = await LoginModel.expLogin(this.loginForm.account, this.loginForm.password, "schoolName", this.loginForm.schoolName, this.superUserForm.account)
          }
          this.userInfo = resultData
          this.jumpToIndex()
        }
      });
    },
    // 根据角色跳转
    jumpToIndex() {
      if (getQuery("returnUrl")) {// 如果存在返回页面
        window.location.href = unescape(getQuery("returnUrl"))
        return
      }
      if (this.userInfo.rolename === 'teacher') {
        RouterModel.routerPush(this, '/userInfo', '个人信息', {}, '3')
      }
      if (this.userInfo.rolename === 'student') {
        RouterModel.routerPush(this, '/student/expList?status=1', '我的实验-进行中', {}, '0-0')
      }
    },
    // 点击补充信息确认按钮
    clickAddInfoBtn() {
      this.$refs.addInfoForm.validate(async valid => {
        if (valid) {
          if (await LoginModel.addUserInfo(this.userInfo.userid, this.addInfoForm.password1, this.addInfoForm.email)) {
            msg_success("完善信息成功")
            this.jumpToIndex()
          }
        } else {
          return false
        }
      })
    },
    // 点击忘记密码按钮
    clickForgetPasswordBtn() {
      if (!this.schoolId) {
        if (this.loginForm.schoolName === "") {
          msg_err("请先输入学校名称!")
          return
        }
      }

      this.findPasswordShow = true
      if (this.loginForm.account) {
        this.resetForm.account = this.loginForm.account
        if (this.loginForm.asStudentLogin) {
          this.resetForm.teacherid = this.loginForm.teacherid
          this.resetForm.teacherList = this.loginForm.teacherList
          this.resetForm.asStudentLogin = true
        } else {
          this.resetForm.teacherList = []
          this.resetForm.teacherid = ""
          this.resetForm.asStudentLogin = false
        }
      } else {
        this.resetForm.account = ""
        this.resetForm.teacherList = []
        this.resetForm.teacherid = ""
        this.resetForm.asStudentLogin = false
      }
      this.resetForm.email = ""
      setTimeout(() => {
        this.$refs["resetForm"].clearValidate();
      }, 300)
    },
    // 忘记密码框-账户输入改变
    async onResetAccountChange(account) {

    },
    // 点击重置密码按钮
    clickResetPasswordBtn() {
      this.$refs.resetForm.validate(async valid => {
        if (valid) {
          let resultData = ""
          if (this.schoolId) {
            resultData = await LoginModel.resetPassword(this.resetForm.account, "schoolId", this.schoolId, this.resetForm.email, this.resetForm.teacherid)
          } else {
            if (this.loginForm.schoolName) {
              resultData = await LoginModel.resetPassword(this.resetForm.account, "schoolName", this.loginForm.schoolName, this.resetForm.email, this.resetForm.teacherid)
            } else {
              msg_err("请先输入学校名称!")
            }
          }
          msg_success("重置密码邮箱发送成功，请到您绑定的邮箱中按照新邮件提示操作！")
          this.findPasswordShow = false;
        } else {
          return false
        }
      })
    },
  }
}
</script>

<style lang="less">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

@bg: #f9f9f9;
@light_gray: #fff;
@cursor: #fff;

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 90%;

    &:nth-child(1) {
      width: 100%;
    }

    input {
      background: @bg;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 10px 5px 10px 13px;
      color: #333;
      height: 47px;
      font-size: 14px;


      &:-webkit-autofill {
        box-shadow: inset 0px 60px 0px @bg;;
        -webkit-text-fill-color: #333 !important;
        color: #222;
        font-size: 14px;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: @bg;
    border-radius: 5px;
    color: #454545;
    margin-bottom: 20px;
  }
}
</style>

<style lang="less" scoped>
// 登录按钮框
.btn-box {
  position: relative;

  .more-btn {
    position: absolute;
    left: 0px;
    top: 5px;
    color: #fff;
  }

  .btn-forget {
    position: absolute;
    right: 0px;
    top: 5px;
    color: #fff;
  }
}

.bottomTips {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 20px;
  color: #fff;
  font-size: 13px;
  text-align: center;
  z-index: 0;

  div {
    margin-top: 3px;
  }
}

@bg: #2d3a4b;
@dark_gray: #889aa4;
@light_gray: #fff;

.login-container {
  min-height: 100%;
  width: 100%;

  overflow: hidden;

  .login-form {
    position: relative;
    width: 550px;
    max-width: 100%;
    padding: 0px 45px;
    margin: 0 auto;
    z-index: 2;
  }

  .tips, .mobile-bottom-tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;


    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: @dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;
    padding-top: 160px;

    .title-img {
      text-align: center;

      img {
        cursor: pointer;
        width: 100px;
        height: 100px;
        border-radius: 50px;
      }
    }

    .title {
      font-size: 30px;
      color: @light_gray;
      margin: 0px auto 20px auto;
      text-align: center;
      font-weight: bold;
    }

    .sub-title {
      font-size: 26px;
      color: @light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }

    .set-language {
      color: #fff;
      position: absolute;
      top: 3px;
      font-size: 18px;
      right: 0px;
      cursor: pointer;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: @dark_gray;
    cursor: pointer;
    user-select: none;
  }
}

.cb-slideshow {
  margin: 0px;
}

.cb-slideshow,
.cb-slideshow:after {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: -1;
}

.cb-slideshow li {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  opacity: 1;
  z-index: 0;
  -webkit-backface-visibility: hidden;
}

.system-tips {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #FFFFFF;
}

.teacher-tip {
  position: absolute;
  right: 30px;
  top: 5px;
  color: #777;
}
</style>
